import { z } from 'zod'
import { KnownProps } from './schemas'

function hasProp<T extends object, K extends PropertyKey>(
  obj: T,
  prop: K
): obj is Extract<T, { [P in K]: unknown }> {
  return prop in obj
}

export const parseOrString = (obj: string) => {
  try {
    const parsed = JSON.parse(obj)
    // "4" is valid JSON and returns 4, but we want to return a string
    if (isNaN(Number(parsed))) {
      return parsed
    } else {
      return parsed + ''
    }
  } catch {
    return obj
  }
}

export const createPropParser = <TSchema>(
  propName: KnownProps,
  schema: z.Schema<TSchema>
): z.ZodType<TSchema, z.ZodTypeDef, unknown> & { propertyName: KnownProps } => {
  const parser = Object.create(
    z.preprocess(
      arg =>
        arg !== undefined &&
        arg !== null &&
        typeof arg === 'object' &&
        hasProp(arg, propName) &&
        arg[propName] !== null
          ? typeof arg[propName] === 'string'
            ? parseOrString(arg[propName])
            : arg[propName]
          : undefined,
      schema
    )
  )
  parser.propertyName = propName
  return Object.freeze(parser)
}
